body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff ;
  background-color: #000 !important;
  /* font-family: Outfit, Ubuntu; */
  font-size: 14px;
  line-height: 1.2;
  height: 100vh;
  /* min-height: 100%; */
  /* min-height: 100%; */
position: relative;
  scroll-behavior: smooth;
 overflow: auto !important;
 overflow-x: hidden !important;
  letter-spacing: "normal";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* body::-webkit-scrollbar {
  display: none;
} */
#root{
position: absolute;
width: 100%;
}