
.openaibannerbox {
  justify-content: center;
  display: flex;
  padding: 16px;
}

.rainbow {
  text-align: center;
  text-decoration: underline;
  font-family: monospace;
  font-weight: bold;
}
.rainbow_text_animated {
  background: linear-gradient(
    to right,
    #6666ff,
    #0099ff,
    #00ff00,
    #ff3399,
    #6666ff
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: rainbow_animation 6s ease-in-out infinite;
  background-size: 400% 100%;
  display: flex;
  justify-content: space-around;
}

.rainbow_text {
  background: linear-gradient(
    to right,
    #6666ff,
    #0099ff,
    #00ff00,
    #ff3399,
    #6666ff
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

@keyframes rainbow_animation {
  0%,
  100% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 0;
  }
}


.macbook {
  padding: 4% 6%;
  margin: 0 auto;
  max-width: 800px;
}

.screen {
  background: #000;
  border-radius: 3% 3% 0.5% 0.5% / 5%;
  margin: 0 auto;
  /* for aspect ratio */
  position: relative;
  width: 80%;
}

.screen:before {
  border: 2px solid #cacacc;
  border-radius: 3% 3% 0.5% 0.5% / 5%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.8) inset,
    0 0 1px 2px rgba(255, 255, 255, 0.3) inset;
  content: "";
  display: block;
  /* for aspect ratio ~67:100 */
  padding-top: 67%;
}

.screen:after {
  content: "";
  border-top: 2px solid rgba(255, 255, 255, 0.15);
  position: absolute;
  bottom: 0.75%;
  left: 0.5%;
  padding-top: 1%;
  width: 99%;
}

.viewport {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  margin: 4.3% 3.2%;
  background: #333;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  top: -10px;
}

.base {
  /* for aspect ratio */
  position: relative;
  width: 100%;
}

.base:before {
  content: "";
  display: block;
  /* for aspect ratio ~33:1000*/
  padding-top: 3.3%;
  /* stylin */
  background: linear-gradient(
    #eaeced,
    #edeef0 55%,
    #fff 55%,
    #8a8b8f 56%,
    #999ba0 61%,
    #4b4b4f 84%,
    #262627 89%,
    rgba(0, 0, 0, 0.01) 98%
  );
  border-radius: 0 0 10% 10%/ 0 0 50% 50%;
}

.base::after {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.5),
    rgba(255, 255, 255, 0.8) 0.5%,
    rgba(0, 0, 0, 0.4) 3.3%,
    transparent 15%,
    rgba(255, 255, 255, 0.8) 50%,
    transparent 85%,
    rgba(0, 0, 0, 0.4) 96.7%,
    rgba(255, 255, 255, 0.8) 99.5%,
    rgba(0, 0, 0, 0.5) 100%
  );
  content: "";
  height: 53%;
  position: absolute;
  top: 0;
  width: 100%;
}

.notch {
  background: #ddd;
  border-radius: 0 0 7% 7% / 0 0 95% 95%;
  box-shadow: -5px -1px 3px rgba(0, 0, 0, 0.2) inset,
    5px -1px 3px rgba(0, 0, 0, 0.2) inset;
  margin-left: auto;
  margin-right: auto;
  margin-top: -3.5%;
  z-index: 2;
  /* for aspect ratio */
  position: relative;
  width: 14%;
}

.notch:before {
  content: "";
  display: block;
  /* for aspect ratio ~1:10 */
  padding-top: 10%;
}

/* Screenshot Hover Scroll Additions
-------------------------------------------------------------- */

.macbook .viewport {
  transition: background-position 3s ease;
  /* background-position: 0 0; */
  background-size: 100% auto;
  overflow: hidden;
  cursor: pointer;
}

.macbook:hover .viewport {
  /* background-position: 0 100%; */
}

.macbook .viewport:after {
  background: radial-gradient(
    circle at right bottom,
    transparent 75%,
    rgba(255, 255, 255, 0.05) 75%,
    transparent
  );
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

/* Crousel infinite */

.carousel-container {
  overflow: hidden;
  position: relative;
  background: linear-gradient(to right, black 0%, rgba(0, 0, 0, 0) 100%);
  width: 65vw; /* Increased width for better responsiveness */
  height: 230px; /* Adjust as needed for mobile view */
  max-width: 100%; /* Ensure it doesn’t exceed screen width */
  margin: 0 auto; /* Center the carousel */
}

.carousel-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.carousel-card > img {
  height: 100%;
  width: 270px;
}
@media (max-width: 900px) {
  .carousel-card > img {
    height: 60%;
    width: 270px;
  }
}

.carousel-track {
  display: flex;
  position: absolute;
  left: 0;
  justify-content: center;
  align-items: center;
  gap: 0px;
  width: 200%;
  height: 100%;
  animation: slide 20s linear infinite;
}

@keyframes slide {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

.carousel-track:hover {
  animation-play-state: paused;
}

.stacked-images-container {
  display: none; /* Hide by default */
}

.stacked-image {
  margin: 8px 0; /* Space between stacked images */
  /* border:1px solid red; */
 
}

.stacked-image > img {
  width: 100%; /* Full width of the container */
  height: auto; /* Maintain aspect ratio */
}

@media (max-width: 600px) {
  .carousel-container {
    display: none; /* Hide carousel on mobile */
  }
  
  .stacked-images-container {
    display: block; /* Show stacked images on mobile */
   
    width: 55vw; /* Full width on mobile */
    
    margin: 0 auto; /* Center the images */
  }
}
